import { Store } from '@reduxjs/toolkit';
import { Modal, notification } from 'antd';
import axios from 'axios';
import React from 'react';

import { decryptResponseMessage } from '../utils/api';
import { clearStorage } from '../utils/storageHandler';

const Axios = axios.create({});

let store: Store;

export const injectStore = (_store: Store) => {
  store = _store;
};

const proceed401Endpoints = ['/login'];

const leadPgpRoute = '/portfolio-growth-plan';

const prevent206MessageInRoute = [leadPgpRoute];
const prevent400MessageInRoute = [leadPgpRoute];

const showWarningMessage = (warningMessage: string) => {
  if (
    warningMessage &&
    warningMessage !== '' &&
    warningMessage !== null &&
    warningMessage !== undefined
  ) {
    notification.info({
      message: 'Info',
      description: warningMessage,
    });
  }
};

const { confirm } = Modal;

const confirmPropertyUpdateHandler = () =>
  new Promise((resolve) => {
    confirm({
      title: 'Are you sure you want to continue?',
      content:
        'This change will deactivate this and all subsequent properties, and will require reviewing their respective Purchase Scenarios.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onCancel: () => {
        resolve(false);
        Modal.destroyAll();
      },
      onOk() {
        resolve(true);
      },
    });
  });
// Add a request interceptor
Axios.interceptors.request.use(
  async (config) => {
    if (
      (config &&
        config.url &&
        config.method === 'post' &&
        (config.url.indexOf('Info') > -1 ||
          config.url.indexOf('Plan') > -1 ||
          config.url.indexOf('PurchaseScenario') === 33 ||
          config.url.indexOf('CashRequiredEstimate') > -1 ||
          config.url.indexOf('AnnualFigures') > -1 ||
          config.url.indexOf('MonthlyModelling') > -1) &&
        config.url.indexOf('Info/notes') === -1 &&
        config.url.indexOf('Info/sell') === -1 &&
        config.url.indexOf('Info/duplicate') === -1 &&
        config.url.indexOf('/Address') === -1 &&
        config.url.indexOf('Plan/properties') === -1 &&
        config.url.indexOf('Plan/add-property') === -1 &&
        config.url.indexOf('Plan/create-plan') === -1 &&
        config.url.indexOf('Plan/delete') === -1) ||
      (config &&
        config.url &&
        config.method === 'patch' &&
        config.url.indexOf('Info/undo-sell') > -1)
    ) {
      const state = store.getState();
      const properties = state?.property?.list;
      const selectedPropertyId = state?.property?.selected_property_id;

      let isPropertyActive = false;
      if (properties && properties?.length > 1) {
        let selectedPropertyIndex = undefined;
        for (let i = 0; i < properties.length; i++) {
          if (properties[i].propertyId === selectedPropertyId) {
            selectedPropertyIndex = i;
            isPropertyActive = properties[i].isActive;
            break;
          }
        }

        /**
         * If the property is last property, and the user is updating the
         * purchase scenario then don't show the warning message.
         */
        if (
          selectedPropertyIndex === properties.length - 1 &&
          config.url.indexOf('purchase-scenario') > -1
        ) {
          return config;
        }

        if (isPropertyActive === true) {
          const isSuccess = await confirmPropertyUpdateHandler();
          if (isSuccess) {
            return config;
          } else {
            return Promise.reject();
          }
        } else {
          return config;
        }
      } else {
        return config;
      }
    } else {
      // Do something before request is sent
      return config;
    }
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (response.status === 206) {
      /**
       * NOTE: This is dashdot's internal logic that we are using to show info
       * message in API.
       */

      if (
        !prevent206MessageInRoute.some((route) =>
          window.location.pathname.includes(route),
        )
      ) {
        showWarningMessage(
          decryptResponseMessage(response.data, 'responseMessage'),
        );

        return Promise.reject();
      }

      return response;
    } else {
      return response;
    }
  },
  (error) => {
    let errorDescription;
    const requestUrl = error.config.url;

    const responseError = decryptResponseMessage(
      error?.response?.data,
      'ResponseMessage',
    );

    if (responseError) {
      errorDescription = responseError;
    }

    // Log out user
    if (
      (error.response.status === 401 &&
        !proceed401Endpoints.some((endpoint) =>
          requestUrl.includes(endpoint),
        )) ||
      error.response.status === 403
    ) {
      // Display error message for status code 403
      notification.error({
        message: 'Error',
        // @ts-ignore
        description: errorDescription,
      });

      // For signup we don't have to redirect the user to login page again.
      if (!error.config.url.includes('/Auth/join')) {
        clearStorage();
        window.location.href = '/';
      }

      return Promise.reject(error);
    }

    if (error.response.status === 400) {
      // Bad request handler

      if (errorDescription?.includes('|')) {
        // We need to make sure one validation error appear on each link

        // Display error notification

        return notification.error({
          message: 'Error',
          // @ts-ignore
          description: (
            <>
              {errorDescription.split('|').map((message: any) => (
                <span key={message}>
                  {/* Remove single quote from error response. */}
                  {message.replace(/'/g, '').trim()}
                  <br />
                </span>
              ))}
            </>
          ),
        });
      }
    }

    // Prevent the error notification from displaying when error occured on the create client EP
    // and status is conflict 409
    // Also prevent when doing a search client and is not found 404
    if (
      !(
        (
          error.config.url.includes('/create-client') &&
          error.response.status === 409
        ) // TD: Review if condition is still applicable
      ) &&
      !(
        error.config.url.includes('/Client/') && error.response.status === 404
      ) &&
      !prevent400MessageInRoute.some((route) =>
        window.location.pathname.includes(route),
      )
    ) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      notification.error({
        message: 'Error',
        // @ts-ignore
        description: errorDescription,
      });
    }

    return Promise.reject(error);
  },
);

export default Axios;

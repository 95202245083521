import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';
import { RootState } from '../../../store';

export const createClient = createAsyncThunk(
  'client/create-client',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    try {
      const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
      const options = getBasicAuthAPIOptions(clientIp, apiParams.token);

      const response = await axios.post(
        `${env_constants.PP_API_BASE_URL}/Client`,
        apiParams.data,
        {
          headers: {
            ...options.headers,
            'content-type': 'multipart/form-data',
          },
        },
      );
      const data = decryptData(response.data);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        // @ts-ignore
        error && error.response && error.response.data
          ? // @ts-ignore
            decryptData(error.response.data)
          : '',
      );
    }
  },
);

export const editClientName = createAsyncThunk(
  'client/edit-client-name',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    try {
      const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
      const options = getBasicAuthAPIOptions(clientIp, apiParams.token);
      const encrypted = encryptData(apiParams.data);

      const response = await axios.put(
        `${env_constants.PP_API_BASE_URL}/Client`,
        encrypted,
        {
          headers: {
            ...options.headers,
          },
        },
      );

      const data = decryptData(response.data);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        // @ts-ignore
        error && error.response && error.response.data
          ? // @ts-ignore
            decryptData(error.response.data)
          : '',
      );
    }
  },
);

export const fetchClients = createAsyncThunk(
  'client/get',
  async (apiParams: { searchValue: string; token: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = getBasicAuthAPIOptions(clientIp, apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Client/${apiParams.searchValue}`,
      options,
    );

    const data = decryptData(response.data);
    return data.clients;
  },
);

export const fetchClientEmails = createAsyncThunk(
  'client/get-emails',
  async (apiParams: { clientId: string; token: string }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = getBasicAuthAPIOptions(clientIp, apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Client/${apiParams.clientId}/emails`,
      options,
    );

    const data = decryptData(response.data);
    return data.clientEmails;
  },
);

export const clientAddEmail = createAsyncThunk(
  'client/add-email',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    try {
      const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
      const options = getBasicAuthAPIOptions(clientIp, apiParams.token);
      const encrypted = encryptData(apiParams.data);

      const response = await axios.post(
        `${env_constants.PP_API_BASE_URL}/Client/add-email`,
        encrypted,
        {
          headers: {
            ...options.headers,
          },
        },
      );

      const data = decryptData(response.data);

      return data.clientEmails;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        // @ts-ignore
        error && error.response && error.response.data
          ? // @ts-ignore
            decryptData(error.response.data)
          : '',
      );
    }
  },
);

export const deleteEmail = createAsyncThunk(
  'client/delete-email',
  async (apiParams: { token: string; data: any }, thunkAPI) => {
    try {
      const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
      const options = getBasicAuthAPIOptions(clientIp, apiParams.token);
      const encrypted = encryptData(apiParams.data);

      const response = await axios.delete(
        `${env_constants.PP_API_BASE_URL}/Client/delete-email`,
        {
          ...options,
          data: encrypted,
        },
      );

      const resData = decryptData(response.data);

      return resData.clientEmails;
    } catch (error) {
      console.log(error);
    }
  },
);

export type UserActivityReqType = {
  userId: string;
  type: 'TypeForm' | 'Cta';

  /* When type is "TypeForm" */
  stage?: 'Started' | 'Question' | 'Accessed';
  questionRef?: string | null;
  planId?: string | null;

  /* When type is "Cta" */
  ctaType?: 'Pgp' | 'Remi';
};

export const trackUserActivity = createAsyncThunk(
  'client/user-activity',
  async (apiParams: { token: string; data: UserActivityReqType }, thunkAPI) => {
    const clientIp = (thunkAPI.getState() as RootState).auth.clientIp;
    const options = {
      ...getBasicAuthAPIOptions(clientIp, apiParams.token),
    };

    const encryptedData = encryptData({
      ...apiParams.data,
    });

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Client/user-activity`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);
